import React, { useEffect, useState } from "react";
import { Table, TableProps } from "antd";
import { Button, Modal } from "antd";
import img1 from "../assets/delete.png";
import "../../src/index.css";
import "../../src/App.scss";
import img from "../assets/images/logo.png";
import Login from "../login/Login";
import Header from "../component/Header";
import pdf from "../assets/pdf .png";
import share from "../assets/share .png";
import { Link } from "react-router-dom";
import PdfView from "../accordian/PdfView";
import { triggerNotification } from "../component/Toster";

const Quotes = () => {
  const [allData, setAllData] = useState<any>(null);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [allFormData, setAllFormData] = useState<any>(null);
  const [pdfType, setPdfType] = useState<any>('GWS');

  useEffect(() => {
    let data = JSON.parse(sessionStorage.selectedQuote);
    setAllData(data);

    let _pdfType = JSON.parse(JSON.parse(sessionStorage.selectedQuote).quote_data).rtoValue;
    if (JSON.parse(JSON.parse(sessionStorage.selectedQuote).quote_data).rtoValue) {
      setPdfType(_pdfType);
    }

  }, []);

  const commonClass = (text: any) => (
    <div>
      <p className=" font-normal text-black">{text || "-"}</p>
    </div>
  );

  // const formatIndianCurrency = (amount: any) => {
  //   const formattedAmount = parseFloat(amount).toFixed(2);
  //   const parts = formattedAmount.split(".");
  //   const rupees = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   return "₹" + rupees + "." + parts[1];
  // };

  const formatIndianCurrency = (amount: any) => {
    // Ensure the amount is a number and remove any existing commas
    const parsedAmount = parseFloat(amount.toString().replace(/,/g, "")).toFixed(2);

    // Split into integer and decimal parts
    const parts = parsedAmount.split(".");

    // Format the integer part with commas for Indian numbering system
    const integerPart = parts[0];
    let lastThreeDigits = integerPart.substring(integerPart.length - 3);
    let otherDigits = integerPart.substring(0, integerPart.length - 3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }
    const formattedRupees = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    // Combine and return the formatted amount
    return "₹" + formattedRupees + "." + parts[1];
  };

  // Function to aggregate data by SKU
  const aggregateData = (newList: any) => {
    if (newList.invoice_data) {
      const aggregatedData: any = {};
      let list = JSON.parse(newList.invoice_data);
      let transationType = list.transationType;

      let transationTypeValue = 0;
      if (transationType == 'new_business') {
        transationTypeValue = 20;
      }
      if (transationType == 'renewal') {
        transationTypeValue = 12;
      }
      if (transationType == 'transfer_business') {
        transationTypeValue = 5;
      }
      const priceGivenByGoogle = list.priceGivenByGoogle;

      list && list.skuNameList && list.skuNameList.forEach((skuObj: any, index: any) => {
        const skus = Array.isArray(skuObj.sku) ? skuObj.sku : [skuObj.sku];
        const qtys = Array.isArray(list.qtyList[index].qty) ? list.qtyList[index].qty : [list.qtyList[index].qty];
        const customerPrices = Array.isArray(list.customerPrice[index].customerPrice) ? list.customerPrice[index].customerPrice : [list.customerPrice[index].customerPrice];
        const googlePrices = Array.isArray(list.costToGoogle[index].googlePrice) ? list.costToGoogle[index].googlePrice : [list.costToGoogle[index].googlePrice];
        const _newCosttoGoogle = Array.isArray(list.costToGoogle[index].googlePrice) ? list.costToGoogle[index].googlePrice : [list.costToGoogle[index].googlePrice];
        // const newCosttoGoogle = _newCosttoGoogle * transationTypeValue / 100;


        skus.forEach((sku: any, subIndex: any) => {
          const qty = parseFloat(qtys[subIndex]) || 0;
          const customerPrice = parseFloat(customerPrices[subIndex]) || 0;
          const googlePrice = parseFloat(googlePrices[subIndex]) || 0;
          const newCosttoGoogle = parseFloat(_newCosttoGoogle[subIndex]) || 0;
          const rowNewCosttoGoogle = priceGivenByGoogle ? newCosttoGoogle : (newCosttoGoogle - newCosttoGoogle * transationTypeValue / 100);

          if (!aggregatedData[sku]) {
            aggregatedData[sku] = [];
          }

          aggregatedData[sku].push({
            qty,
            customerPrice,
            googlePrice,
            totalCustomerPrice: qty * customerPrice,
            totalCostToGoogle: qty * rowNewCosttoGoogle,
            // margin: customerPrice - googlePrice,
            margin: customerPrice - rowNewCosttoGoogle,
            totalMargin: qty * (customerPrice - rowNewCosttoGoogle),
            newCosttoGoogle: rowNewCosttoGoogle
          });
        });
      });

      return aggregatedData;
    }
  };
  const avargeArpu = (row: any) => {
    // let q = row.reduce((acc: any, data: any) => acc + data.qty, 0);
    // let amt = row.reduce((acc: any, data: any) => acc + data.totalMargin, 0);
    // return amt / q;

    let q = row.reduce((acc: any, data: any) => acc + data.qty, 0);
    let amt = row.reduce((acc: any, data: any) => acc + data.totalMargin, 0);
    return amt / q;

  };
  const calculateTotal = (data: any, key: any) => {
    let q = data.reduce((acc: any, row: any) => acc + row.qty, 0);
    let amt = data.reduce((sum: any, row: any) => sum + (row.qty * (row[key] || 0)), 0);
    return amt / q;
  }

  const removeHyphens = (value: any) => {
    let _value = value;
    let result = _value.replace(/-/g, ' '); // Remove all hyphens
    return result;
  }
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getPlan = (data: any) => {
    let value: any = '-';
    if (data) {
      if (data.type) {
        if (data.type === "plan") {
          value = data.planType ? capitalizeFirstLetter(data.planType) : '-';
          value = <b>{value}</b>
        }
        if (data.type === "product") {
          // Loop through the optionsList and format each product name individually
          value = data.optionsList.length > 0
            ? data.optionsList.map((option: string) => (
              <div key={option}>
                <b>{capitalizeFirstLetter(option)}</b>
              </div>
            ))
            : '-';
        }
      }
    }
    return value;
  };

  const getTotalPrice = (licenses: any, customerGivenPrice: any) => {
    let amt = 0;
    if (licenses) {
      if (customerGivenPrice) {
        amt = licenses * customerGivenPrice;
      }
    }
    return formatIndianCurrency(amt);
  }
  // Component to render the table
  const DataTable = (list: any) => {
    console.log(list);
    if (list) {
      if (list && list.list && list.list.quote_data) {
        if (JSON.parse(list.list.quote_data) && JSON.parse(list.list.quote_data).rtoValue) {
          if (JSON.parse(list.list.quote_data).rtoValue == 'Akku') {
            return akkuTable(list);
          } else {
            return gwsTable(list);
          }
        } else {
          return gwsTable(list);
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const akkuTable = (list: any) => {
    return (
      <table className="w-full priceTable ">
        <thead className="bg-white border-b rounded-t-xl border-[#C7C7C7]">
          <tr>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Plan / Product</th> {/* 1 */}
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">License</th> {/* 2 */}
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Customer Price</th> {/* 3 */}
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Proposed price</th> {/* 3 */}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-[#E9E9E9] border-t border-[#C7C7C7] font-semibold total">
            <td className="p-4  text-[11px] xl:text-[16px]">
              {getPlan(JSON.parse(list.list.quote_data).allData)}
            </td>
            <td className="p-4  text-[11px] xl:text-[16px]">
              <b>{JSON.parse(list.list.quote_data).allData.licenses ? JSON.parse(list.list.quote_data).allData.licenses : '-'}</b>
            </td>
            <td className="p-4  text-[11px] xl:text-[16px]">
              <b>{JSON.parse(list.list.quote_data).allData.CustomerGivenPrice ? formatIndianCurrency(JSON.parse(list.list.quote_data).allData.CustomerGivenPrice) : '-'}</b>
            </td>
            <td className="p-4  text-[11px] xl:text-[16px]">
              <b>{getTotalPrice(JSON.parse(list.list.quote_data).allData.licenses, JSON.parse(list.list.quote_data).allData.CustomerGivenPrice)}</b>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  const gwsTable = (list: any) => {
    const aggregatedData = aggregateData(list.list);
    let totalProposedPrice = 0;
    let totalCostToGoogle = 0;
    let totalMargin = 0;
    // Calculate the total of all proposed prices
    if (aggregatedData) {
      Object.values(aggregatedData).forEach((skuData: any) => {
        skuData.forEach((row: any) => {
          totalProposedPrice += row.totalCustomerPrice; // Assuming proposed price is `totalCustomerPrice`
          totalCostToGoogle += row.totalCostToGoogle;
          totalMargin += row.totalMargin;
        });
      });
    }
    // Calculate IGST (18%) and Total
    const igst = totalProposedPrice * 0.18; // 18% of the subtotal
    const totalWithTax = totalProposedPrice + igst;

    return (
      <table className="w-full priceTable ">
        <thead className="bg-white border-b rounded-t-xl border-[#C7C7C7]">
          <tr>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">SKU</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Quantity</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">List Price</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Customer Price</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Cost to CNW</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">ARPU/Year</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Total Cost to CNW</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Proposed Price</th>
            <th className="p-4 text-left text-[#6A6A65] text-[11px] xl:text-[14px]">Total Margin/Year</th>
          </tr>
        </thead>

        <tbody>
          {Object.entries(aggregatedData).map(([sku, rows]: any) => (
            <>
              {rows.map((data: any, index: any) => (
                <tr key={`${sku}-${index}`} className=" w-[100%] bg-white">
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{removeHyphens(sku)}</td> {/* SKU */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{data.qty}</td> {/* Quantity */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{formatIndianCurrency(data.googlePrice)}</td> {/* List Price */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{formatIndianCurrency(data.customerPrice)}</td> {/* Customer Price */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{formatIndianCurrency(data.newCosttoGoogle)}</td> {/* Cost to CNW */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{formatIndianCurrency(data.margin)}</td> {/* ARPU/Year */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{formatIndianCurrency(data.totalCostToGoogle)}</td> {/* Total Cost to CNW */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{formatIndianCurrency(data.totalCustomerPrice)}</td> {/* Proposed Price */}
                  <td className="p-4 text-black text-[13px] xl:text-[14px]">{formatIndianCurrency(data.totalMargin)}</td> {/* Total Margin/Year */}
                </tr>
              ))}
              <tr key={`${sku}-total`} className="bg-[#E9E9E9] border-t border-[#C7C7C7] font-semibold total">
                <td className="p-4">
                  <b>Total</b>
                </td>
                <td className="p-4  text-[13px] xl:text-[15px]"> {/* Quantity */}
                  <b>{rows.reduce((acc: any, data: any) => acc + data.qty, 0)}</b>
                </td>
                <td className="p-4 text-[13px] xl:text-[15px]"> {/* List Price */}
                  <b>{formatIndianCurrency(calculateTotal(rows, 'googlePrice'))}</b>
                </td>
                <td className="p-4 text-[13px] xl:text-[15px]"> {/* Customer Price */}
                  <b>{formatIndianCurrency(calculateTotal(rows, 'customerPrice'))}</b>
                </td>
                <td className="p-4 text-[13px] xl:text-[15px]">  {/* Cost to CNW */}
                  <b>{formatIndianCurrency(calculateTotal(rows, 'newCosttoGoogle'))}</b>
                </td>
                <td className="p-4 text-[13px] xl:text-[15px]">  {/* ARPU/Year */}
                  <b>{formatIndianCurrency(avargeArpu(rows))}</b>
                </td>
                <td className="p-4 text-[13px] xl:text-[15px]"> {/* Total Cost to CNW */}
                  <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.totalCostToGoogle, 0))}</b>
                </td>
                <td className="p-4 text-[13px] xl:text-[15px]"> {/* Proposed Price */}
                  <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.totalCustomerPrice, 0))}</b>
                </td>
                <td className="p-4 text-[13px] xl:text-[15px]">  {/* Total Margin/Year */}
                  <b>{formatIndianCurrency(rows.reduce((acc: any, data: any) => acc + data.totalMargin, 0))}</b>
                </td>
              </tr>
            </>
          ))}
        </tbody>
        {/* Add the final row with Proposed Price Total */}
        {/* Add SubTotal, IGST, and Total Rows */}
        <tr className="bg-[#ffffff] border-t font-bold border-[#a7a1a1]">
          <td colSpan={5} className="p-4 text-left text-[11px] xl:text-[14px]"></td>
          <td className="p-4 text-left text-[14x] xl:text-[16px]">
            <b>Total</b>
          </td>
          <td className="p-4 text-[14px] xl:text-[16px]">
            <b>{formatIndianCurrency(totalCostToGoogle)}</b>
          </td>
          <td className="p-4 text-[14px] xl:text-[16px]">
            <b>{formatIndianCurrency(totalProposedPrice)}</b>
          </td>
          <td className="p-4 text-[14px] xl:text-[16px]">
            <b>{formatIndianCurrency(totalMargin)}</b>
          </td>
        </tr>


        {/* let totalProposedPrice = 0;
        let totalCostToGoogle = 0;
        let totalMargin = 0; */}
      </table>
    );
  }
  const handleClose = () => {
    setIsOpenPopup(false);
  };
  const handleOpen = () => {
    let data = JSON.parse(allData.invoice_data);
    setAllFormData(data);
    setIsOpenPopup(true);
    // triggerNotification("success", "Success", "Invoice Generated Successfully", "topRight");
  };
  return (
    <div>
      <Header></Header>

      <div className="    mx-auto   w-[95%] xl:w-[97%] 2xl:w-[95%] mt-[96px]		xl:mt-[120px]">
        <button className="flex bck  ">
          {" "}
          <Link to="/invoice_logs" className=" flex  ">
            <span className="material-symbols-outlined pr-1 pt-0 xl:pt-[2px] text-[20px] xl:text-[24px]">keyboard_backspace</span>Back
          </Link>
        </button>
        <div className="quto ">
          <h3 className="mb-3">Quote</h3>
          {/* <Table dataSource={dataSources} columns={columns1} /> */}
          {allData && <DataTable list={allData} />}
          <div className="quto-cent">
            <button
              type="submit"
              className="flex ml-3 items-center px-9 xl:px-12 py-2 xl:py-3 border bg-[#BD44CA] text-white rounded-[33.6px] border-1"
              onClick={() => {
                handleOpen();
              }}
            >
              <img src={pdf} alt="Download" className="pr-2" />
              View/Download
            </button>
          </div>
        </div>
      </div>
      {isOpenPopup && <PdfView isOpenPopup={isOpenPopup} handleClose={handleClose} allFormData={allFormData} pdfType={pdfType} />}
    </div>
  );
};

export default Quotes;
