import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setQuoteApprovals, getQuoteApprovals } from '../future/invoiceSlice';

import * as Yup from "yup";
import { Select, Table, TableProps } from "antd";
import { Button, Modal, Radio } from "antd";
import img1 from "../assets/delete.png";
import "../../src/index.css";
import "../../src/App.scss";
import img from "../assets/images/logo.png";
import Login from "../login/Login";
import Accordian from "../accordian/Accordian";
import Header from "../component/Header";
import { Formik, Field, FieldArray, ErrorMessage, FormikHelpers, FieldArrayRenderProps, Form } from "formik";
import { gwsPriceFormSchema } from "./Schema/Schema";

import GWSProductItemForm from "./GWSProductItemForm";
import { ProductPricelist, setProductPriceDetails, capitalizeFirstLetter } from "./ProductPriceList";
import { Link } from "react-router-dom";
import { triggerNotification } from "../component/Toster";
import AkkuProductForm from "../pages/AkkuProductForm";
import { apiUrl } from '../services/baseUrl';

export const subplans: any = {
  business: ["Starter", "Standard", "Plus"],
  enterprise: ["Starter", "Standard", "Plus"],
  frontline: ["Basic", "Plus"],
};

// Types
interface Product {
  productType: string;
  subPlan: string;
  licenses: number | "";
  googlePrice: number | "";
  CustomerGivenPrice: number | "";
}

interface FormValues {
  transationType: string;
  contractPeriod: string;
  priceGivenByGoogle: boolean;
  customerCompanyName: string;
  customerName: string;
  email: string;
  customerDomain: string;
  products: Product[];
}

export const populateSubplans = (productType: any) => {
  return subplans[productType] || [];
};

const Newproposal = () => {

  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [activeKeys, setActiveKeys] = useState<any>([]);
  const [visible, setVisible] = useState("0");
  const [isSendQuote, setIsSendQuote] = useState(false);
  const [findForm, setFindForm] = useState('');
  const [findFormFlag, setFindFormFlag] = useState<any>(null);

  const [gwsPriceFormInitValues, setGwsPriceFormInitValues] = useState<FormValues>({
    transationType: "new_business",
    contractPeriod: "1",
    priceGivenByGoogle: false,
    customerCompanyName: "",
    customerName: "",
    email: "",
    customerDomain: "",
    products: [],
  });

  const [productPriceDetails1, setProductPriceDetails1] = useState<any>();
  const [productPriceDetails2, setProductPriceDetails2] = useState<any>();
  const [googleGivenPriceShow, setGoogleGivenPriceShow] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const productFormRef = useRef<any>(null);
  const [isValid, setIsValid] = useState(false);
  const [priceVarifyPercentage, setPriceVarifyPercentage] = useState(11);
  const [allData, setAllData] = useState<any>(null);
  const [invoiceData, setInvoiceData] = useState<any>(null);
  const [rtoValue, setRtoValue] = useState('GWS');
  const [akkuFormUpdatedData, setAkkuFormUpdatedData] = useState<any>(null);


  const dispatch = useDispatch();

  const invoiceApproveStatus = useSelector((state: any) => {
    return state.invoice;
  });

  useEffect(() => {
    getProductPrice();
    dispatch(setQuoteApprovals(null));
  }, []);

  const getProductPrice = () => {
    const { first_20_price, after_20_price } = setProductPriceDetails(ProductPricelist);

    setProductPriceDetails1(first_20_price);
    setProductPriceDetails2(after_20_price);

    getInvoiceData();
  };

  const getInvoiceData = async () => {
    if (sessionStorage.editInvoiceID) {
      try {
        const formdata = new FormData();
        formdata.append("invoice_id", sessionStorage.editInvoiceID);

        const response = await fetch(apiUrl + "getInvoiceByID.php", {
          method: "POST",
          body: formdata,
        });
        const result = await response.json();

        if (result && result.data && result.data[0]) {
          setInvoiceData(result.data[0]);
          setFormData(result.data[0]);

        } else {
          // triggerNotification("error", "Error", "Failed", "topRight", "pop-color");
        }
      } catch (error) {
        // console.error(error);
        // setInvoiceHistoryList([]);
        // triggerNotification("error", "Error", "Failed", "topRight", "pop-color");
      }
    }
  };

  const setFormData = (data: any) => {
    try {
      const invoiceData: any = JSON.parse(data.invoice_data);
      console.log(invoiceData);

      if (invoiceData) {
        if (invoiceData.priceGivenByGoogle !== undefined) {
          const updatedData: any = changeKey(invoiceData.products);
          if (productFormRef.current && updatedData) {
            productFormRef.current.setValues({
              customerName: invoiceData.customerName,
              email: invoiceData.email,
              customerDomain: invoiceData.customerDomain,
              products: updatedData,
              transationType: invoiceData.transationType,
              contractPeriod: invoiceData.contractPeriod,
              priceGivenByGoogle: invoiceData.priceGivenByGoogle,
              customerCompanyName: invoiceData.customerCompanyName,
            });

            // setRtoValue

            if (invoiceData.priceGivenByGoogle) {
              setGoogleGivenPriceShow(true);
            }

            setTimeout(() => {
              document.getElementById("calculate")?.click();
            }, 1000);
            // setTimeout(() => {
            //   document.getElementById("handleAddressOpen")?.click();
            // }, 1200);
          }
        } else {
          setRtoValue('Akku');
          setAkkuFormUpdatedData(data);
        }
      }
    } catch (error) {
      console.error("Failed to set form data:", error);
    }
  };
  const changeKey = (data: any[]) => {
    return data.map((item) => {
      if (item.hasOwnProperty("subplan")) {
        item["subPlan"] = item["subplan"];
        delete item["subplan"];
      }
      return item;
    });
  };

  const handleRemove = (remove: any, index: any) => {
    remove(index);
  };

  const addProductItem = (values: any) => {
    let temp: any = productFormRef.current.values.products;
    const newProduct = {
      productType: values.productType,
      subPlan: values.subPlan,
      licenses: values.licenses,
      googlePrice: googleGivenPriceShow ? values.googlePrice : 0,
      CustomerGivenPrice: values.customerPrice,
    };
    if (!Array.isArray(temp)) {
      temp = [];
    }
    temp.push(newProduct);
    setGwsPriceFormInitValues({
      ...productFormRef.current.values,
      products: temp,
    });

    setForceUpdate((prev) => prev + 1);

    productFormRef.current.validateForm().then((errors: any) => {
      if (errors.products) {
        console.log("Form validation errors after adding product:", errors.products);
      } else {
        console.log("No form validation errors after adding product.");
      }
    });

    document.getElementById('calculate')?.click();

  };

  const handlePriceGivenByGoogle = (name: any, value: any, setFieldValue: any, resetForm: any) => {
    setFieldValue(name, value);
    setGoogleGivenPriceShow(value);
    resetTable(resetForm);
  };

  const handleTransationType = (value: any, name: any, setFieldValue: any, resetForm: any) => {
    setFieldValue("transationType", value);
    if (value === "renewal" || value === "transfer_business") {
      setFieldValue("priceGivenByGoogle", true);
      setGoogleGivenPriceShow(true);
    }
    if (value === "new_business") {
      setPriceVarifyPercentage(11);
    }
    if (value === "renewal") {
      setPriceVarifyPercentage(6);
    }
    if (value === "transfer_business") {
      setPriceVarifyPercentage(3);
    }
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };

  const handleContractPeriodChange = (value: any, setFieldValue: any, resetForm: any) => {
    setFieldValue("contractPeriod", value);
    if (value === "3") {
      setFieldValue("priceGivenByGoogle", true);
      setGoogleGivenPriceShow(true);
      resetTable(resetForm);
      productPriceNotValid();
    } else {
      setGoogleGivenPriceShow(false);
      setFieldValue("priceGivenByGoogle", false);
      resetTable(resetForm);
      productPriceNotValid();
    }
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };
  const productPriceNotValid = () => {
    setVisible("0");
    setActiveKeys([]);
    setAllData(null);
    setIsAccordionOpen(false);
  };
  const handleProductTypeChange = (index: any, value: any, setFieldValue: any) => {
    setFieldValue(`products[${index}].productType`, value);
    productPriceNotValid();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };

  const handleSubPlanChange = (index: any, value: any, setFieldValue: any) => {
    setFieldValue(`products[${index}].subPlan`, value);
    productPriceNotValid();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };

  const handleLicensesChange = (index: any, value: any, setFieldValue: any) => {
    const validValue = value === "" ? 0 : Number(value);
    setFieldValue(`products[${index}].licenses`, validValue);
    productPriceNotValid();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };

  const handleGooglePriceChange = (index: any, value: any, setFieldValue: any) => {
    const validValue = value === "" ? 0 : Number(value);
    setFieldValue(`products[${index}].googlePrice`, validValue);
    productPriceNotValid();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };

  const handleCustomerGivenPriceChange = (index: any, value: any, setFieldValue: any) => {
    const validValue = value === "" ? 0 : Number(value);
    setFieldValue(`products[${index}].CustomerGivenPrice`, validValue);
    productPriceNotValid();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };
  const handleCustomerCompanyName = (value: any, setFieldValue: any) => {
    setFieldValue(`customerCompanyName`, value);
    hideAccordion();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };
  const handleCustomerName = (value: any, setFieldValue: any) => {
    setFieldValue(`customerName`, value);
    hideAccordion();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };
  const handleEmail = (value: any, setFieldValue: any) => {
    setFieldValue(`email`, value);
    hideAccordion();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };
  const handleCustomerDomain = (value: any, setFieldValue: any) => {
    setFieldValue(`customerDomain`, value);
    hideAccordion();
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  };

  const proposalSubmit = async (values: any) => {

    // console.log(values.products);
    // if (findForm == 'mainForm' && isSendQuote == true) {
    // } else {
    //   setFindForm('mainForm');
    //   setIsSendQuote(false);
    // }
    setFindForm('mainForm');
    if (values.priceGivenByGoogle) {
      let response = await comparePrice2(values);
      if (response || isSendQuote || sessionStorage.quoteStatus == '0') {
        const res = await calculate();
        if (res || isSendQuote || sessionStorage.quoteStatus == '0') {
          let item = {
            ...values,
            ...res,
          };
          setAllData(item);
          let checkArpuFlag: any = await checkArpu(item);
          if (checkArpuFlag || isSendQuote || sessionStorage.quoteStatus == '0') {
            setIsAccordionOpen(true);
            setActiveKeys("0");
          } else {
            approvedMessage();
          }
        }
      } else {
        approvedMessage();
      }
    } else {
      let response = await comparePrice1(values);
      if (response || isSendQuote || sessionStorage.quoteStatus == '0') {
        const res = await calculate();
        if (res || isSendQuote || sessionStorage.quoteStatus == '0') {
          let item = {
            ...values,
            ...res,
          };
          setAllData(item);
          let checkArpuFlag: any = await checkArpu(item);
          if (checkArpuFlag || isSendQuote || sessionStorage.quoteStatus == '0') {
            setIsAccordionOpen(true);
            setActiveKeys("0");
          } else {
            approvedMessage();
          }
        }
      } else {
        approvedMessage();
      }
    }
  };

  const approvedMessage = () => {
    productPriceNotValid();
    approvalModalShow();
    // triggerNotification("error", "Alert", "Contact NK and Dinesh to get approvals for this price.", "topRight");
  }
  const checkArpu = (list: any) => {
    let checkMarginAmt = true;
    // Function to aggregate data by SKU

    let transationType = list.transationType;

    let transationTypeValue = 0;
    if (transationType == 'new_business') {
      transationTypeValue = 20;
    }
    if (transationType == 'renewal') {
      transationTypeValue = 12;
    }
    if (transationType == 'transfer_business') {
      transationTypeValue = 5;
    }
    if (transationType == 'new_business') {
      list && list.skuNameList && list.skuNameList.forEach((skuObj: any, index: any) => {
        const skus = Array.isArray(skuObj.sku) ? skuObj.sku : [skuObj.sku];
        const qtys = Array.isArray(list.qtyList[index].qty) ? list.qtyList[index].qty : [list.qtyList[index].qty];
        const customerPrices = Array.isArray(list.customerPrice[index].customerPrice) ? list.customerPrice[index].customerPrice : [list.customerPrice[index].customerPrice];
        const googlePrices = Array.isArray(list.costToGoogle[index].googlePrice) ? list.costToGoogle[index].googlePrice : [list.costToGoogle[index].googlePrice];
        const _newCosttoGoogle = Array.isArray(list.costToGoogle[index].googlePrice) ? list.costToGoogle[index].googlePrice : [list.costToGoogle[index].googlePrice];


        skus.forEach((sku: any, subIndex: any) => {
          const qty = parseFloat(qtys[subIndex]) || 0;
          const customerPrice = parseFloat(customerPrices[subIndex]) || 0;
          const googlePrice = parseFloat(googlePrices[subIndex]) || 0;
          const newCosttoGoogle = parseFloat(_newCosttoGoogle[subIndex]) || 0;
          const rowNewCosttoGoogle = (newCosttoGoogle - newCosttoGoogle * transationTypeValue / 100);


          // let _qty = qty;
          // let _customerPrice = customerPrice;
          // let _googlePrice = googlePrice;
          // let _totalCustomerPrice = qty * customerPrice;
          // let _totalCostToGoogle = qty * googlePrice;
          let _margin = customerPrice - rowNewCosttoGoogle;
          // let _totalMargin = qty * (customerPrice - rowNewCosttoGoogle);
          // let _newCosttoGoogleValue = rowNewCosttoGoogle;

          // Logic for Business-Starter with two iterations
          if (sku == "Business-Starter") {
            let newMargin = subIndex === 0
              ? googlePrice * 18.5 / 100 // First time
              : googlePrice * 11 / 100;  // Second time

            if (newMargin >= _margin) {
              checkMarginAmt = false;
            }
          }

          // Logic for Business-Standard remains unchanged
          if (sku == "Business-Standard") {
            let newMargin = googlePrice * 10 / 100;
            if (newMargin >= _margin) {
              checkMarginAmt = false;
            }
          }


        });
      });
    }
    return checkMarginAmt;
  }

  const comparePrice1 = (values: any) => {
    let result = 0;
    let productsList = values.products;
    let mapErrorFlag = [];

    productsList.map((object: any) => {
      let itemName = object.productType + "" + object.subPlan;

      if (itemName.toLocaleLowerCase() === "businessstarter" || itemName.toLocaleLowerCase() === "businessstandard") {
        let numberOfLicenses = parseInt(object.licenses);
        let checkRemainingLicenses = numberOfLicenses > 20 ? true : false;

        if (checkRemainingLicenses) {
          let CustomerGivenPrice: any = parseFloat(object.CustomerGivenPrice).toFixed(2);
          let total_OfCustomerGivenPrice = numberOfLicenses * CustomerGivenPrice;

          let countAfterTwantyLicenses = numberOfLicenses - 20;

          let basePrice1 = productPriceDetails1[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()];
          let basePrice2 = productPriceDetails2[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()];

          let margin1 = basePrice1 * (priceVarifyPercentage / 100);
          let margin2 = basePrice2 * (priceVarifyPercentage / 100);

          let firstTwantyLicensesAmt = 20 * basePrice1 - 20 * margin1;
          const afterTwantyLicensesAmt = countAfterTwantyLicenses * basePrice2 - countAfterTwantyLicenses * margin2;

          let preTotal = firstTwantyLicensesAmt + afterTwantyLicensesAmt;
          if (total_OfCustomerGivenPrice > preTotal) {
            mapErrorFlag.push(true);
          } else {
            mapErrorFlag.push(false);
            result = result + 1;
          }
        } else {
          let perLicensesAmount = productPriceDetails1[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()];
          let perLicensesWithMarginAmt = perLicensesAmount - calculateMarginAmount(perLicensesAmount);
          let CustomerGivenPrice = parseInt(object.CustomerGivenPrice);
          if (CustomerGivenPrice > perLicensesWithMarginAmt) {
            mapErrorFlag.push(true);
          } else {
            mapErrorFlag.push(false);
            result = result + 1;
          }
        }
      } else {
        let perLicensesAmount = productPriceDetails1[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()];
        let perLicensesWithMarginAmt = perLicensesAmount - calculateMarginAmount(perLicensesAmount);
        let CustomerGivenPrice = parseInt(object.CustomerGivenPrice);
        if (CustomerGivenPrice > perLicensesWithMarginAmt) {
          mapErrorFlag.push(true);
        } else {
          mapErrorFlag.push(false);
          result = result + 1;
        }
      }
    });
    return result == 0 ? true : false;
  };

  const calculateMarginAmount = (perLicensesAmount: any) => {
    return perLicensesAmount * (priceVarifyPercentage / 100);
  };

  const comparePrice2 = (values: any) => {
    let result = 0;
    let productsList = values.products;
    let packagePrice = productsList.map((object: any) => {
      let googlePriceNum = parseFloat(object.googlePrice);
      let customerPriceNum = parseFloat(object.CustomerGivenPrice);
      let difference = customerPriceNum - googlePriceNum;
      let percentageOfGooglePrice = googlePriceNum * (priceVarifyPercentage / 100);
      if (difference >= percentageOfGooglePrice) {
      } else {
        result += 1;
      }
    });
    return result == 0 ? true : false;
  };

  const calculate = () => {
    let skuNameList: any = [];
    let qtyList: any = [];
    let customerPrice: any = [];
    let costToGoogle: any = [];
    let tempProductList = productFormRef.current.values.products;

    if (productFormRef.current.values.priceGivenByGoogle) {
      let result = 0;
      let packagePrice = tempProductList.map((object: any) => {
        let difference = object.CustomerGivenPrice - object.googlePrice;
        let percentageOfGooglePrice = object.googlePrice * (priceVarifyPercentage / 100);
        if (difference >= percentageOfGooglePrice) {
        } else {
          result += 1;
        }
        skuNameList.push({
          sku: capitalizeFirstLetter(object.productType) + "-" + capitalizeFirstLetter(object.subPlan),
        });
        qtyList.push({ qty: object.licenses });
        customerPrice.push({ customerPrice: object.CustomerGivenPrice });
        costToGoogle.push({ googlePrice: object.googlePrice });
      });
    } else {
      let result = 0;
      let packagePrice = tempProductList.map((object: any) => {
        let numberOfLicenses = parseInt(object.licenses);
        let nameType = (object.productType + "" + object.subPlan).toLocaleLowerCase();
        if (nameType === "businessstarter" || nameType === "businessstandard") {
          let checkRemainingLicenses = numberOfLicenses > 20 ? true : false;

          if (checkRemainingLicenses) {
            let _tempSkuNameList = [];
            let _tempQtyList = [];
            let _tempCostToGoogle = [];
            let _tempCustomerPrice = [];

            let CustomerGivenPrice: any = parseFloat(object.CustomerGivenPrice).toFixed(2);
            let total_OfCustomerGivenPrice = numberOfLicenses * CustomerGivenPrice;
            let basePrice1 = productPriceDetails1[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()];
            let basePrice2 = productPriceDetails2[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()];

            let margin1 = basePrice1 * (priceVarifyPercentage / 100);
            let margin2 = basePrice2 * (priceVarifyPercentage / 100);

            let countAfterTwantyLicenses = numberOfLicenses - 20;
            let firstTwantyLicensesAmt = 20 * basePrice1 - 20 * margin1;

            _tempSkuNameList.push(capitalizeFirstLetter(object.productType) + "-" + capitalizeFirstLetter(object.subPlan));
            _tempQtyList.push(20);
            _tempCostToGoogle.push(productPriceDetails1[object.productType][object.subPlan]);
            _tempCustomerPrice.push(object.CustomerGivenPrice);

            const afterTwantyLicensesAmt = countAfterTwantyLicenses * basePrice2 - countAfterTwantyLicenses * margin2;

            _tempSkuNameList.push(capitalizeFirstLetter(object.productType) + "-" + capitalizeFirstLetter(object.subPlan));
            _tempQtyList.push(countAfterTwantyLicenses);
            _tempCostToGoogle.push(productPriceDetails2[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()]);
            _tempCustomerPrice.push(object.CustomerGivenPrice);
            skuNameList.push({ sku: _tempSkuNameList });
            qtyList.push({ qty: _tempQtyList });
            costToGoogle.push({ googlePrice: _tempCostToGoogle });
            customerPrice.push({ customerPrice: _tempCustomerPrice });
            let preTotal = firstTwantyLicensesAmt + afterTwantyLicensesAmt;
            if (total_OfCustomerGivenPrice > preTotal) {
            } else {
              result += 1;
            }
          } else {
            skuNameList.push({
              sku: capitalizeFirstLetter(object.productType) + "-" + capitalizeFirstLetter(object.subPlan),
            });
            qtyList.push({ qty: numberOfLicenses });
            costToGoogle.push({ googlePrice: productPriceDetails1[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()] });
            customerPrice.push({ customerPrice: object.CustomerGivenPrice });

            let perLicensesAmount = productPriceDetails1[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()];
            let perLicensesWithMarginAmt = perLicensesAmount - calculateMarginAmount(perLicensesAmount);
            let CustomerGivenPrice = parseInt(object.CustomerGivenPrice);
            if (CustomerGivenPrice > perLicensesWithMarginAmt) {
            } else {
              result += 1;
            }
          }
        } else {
          qtyList.push({ qty: numberOfLicenses });
          skuNameList.push({ sku: capitalizeFirstLetter(object.productType) + "-" + capitalizeFirstLetter(object.subPlan) });
          customerPrice.push({ customerPrice: object.CustomerGivenPrice });
          costToGoogle.push({ googlePrice: productPriceDetails1[object.productType.toLocaleLowerCase()][object.subPlan.toLocaleLowerCase()] });
          let perLicensesAmount = productPriceDetails1[object.productType][object.subPlan];
          let perLicensesWithMarginAmt = perLicensesAmount - calculateMarginAmount(perLicensesAmount);
          let CustomerGivenPrice = parseInt(object.CustomerGivenPrice);
          if (CustomerGivenPrice > perLicensesWithMarginAmt) {
          } else {
            result += 1;
          }
        }
      });
    }
    return {
      skuNameList: skuNameList,
      qtyList: qtyList,
      customerPrice: customerPrice,
      costToGoogle: costToGoogle,
    };
  };

  const resetTable = (resetForm: any) => {
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
    setAllData(null);
    setActiveKeys([]);
    setIsAccordionOpen(false);
    setTimeout(() => {
      resetForm({
        values: {
          ...productFormRef.current.values,
          products: [],
        },
      });
    }, 1000);
    productPriceNotValid();
  };

  const checkAllFormVarify = async () => {
    try {
      const errors = await productFormRef.current.validateForm();
      return Object.keys(errors).length === 0;
    } catch (error) {
      return false;
    }
  }
  const hideAccordion = () => {
    setActiveKeys([]);
    setIsAccordionOpen(false);
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  }
  const rdoEvent = (event: any) => {
    setGwsPriceFormInitValues({
      transationType: "new_business",
      contractPeriod: "1",
      priceGivenByGoogle: false,
      customerCompanyName: "",
      customerName: "",
      email: "",
      customerDomain: "",
      products: [],
    });
    hideAccordion();
    document.getElementById('handleResetBtn')?.click();
    setRtoValue(event.target.value);
  }
  const handleReset = (resetForm: () => void) => {
    // Call resetForm inside this function
    resetForm();
  };

  const approvalModalShow = () => {
    setApprovalModalOpen(true);
  }
  const approvalModalShowFromItemForm = () => {
    setFindForm('approvalModalShowFromItemForm');
    setApprovalModalOpen(true);
  }
  const approvalModalCancel = () => {
    setApprovalModalOpen(false);
    setIsSendQuote(false);
    dispatch(setQuoteApprovals(null));
    setFindForm('');
  }
  const sendRequestQuote = () => {
    dispatch(setQuoteApprovals(true));
    setIsSendQuote(true);
    setApprovalModalOpen(false);
    if (findForm) {
      if (findForm == 'approvalModalShowFromItemForm') {
        // document.getElementById('addProductBtn')?.click();
        let numb = Math.round(Math.random() * (9999999 - 1111111) + 1111111);
        setFindFormFlag(numb);
      }
      if (findForm == 'mainForm') {
        document.getElementById("calculate")?.click();
      }
    }
  }
  const cancelRequestQuote = () => {
    approvalModalCancel();
  }
  const akkuProductFormData = (values: any) => {
    setAllData(null);
    setIsAccordionOpen(false);

    setTimeout(() => {
      setAllData(values);
      setIsAccordionOpen(true);
      setActiveKeys("0");
    }, 1000);
  }

  const removeItemTrigger = () => {
    setTimeout(() => {
      document.getElementById('calculate')?.click();
      hideAccordion();
    }, 300);
  }
  const handleFormSubmit = async (setFieldValue: any, errors: any) => {
    const isValid = await productFormRef.current.validateForm(); // Check form validation
    if (Object.keys(isValid).length > 0) {
      if (Object.keys(isValid).length == 1) {
        if (errors.products) {
          document.getElementById('addProductBtn')?.click();
          // setTimeout(() => {
          //   document.getElementById('calculate')?.click();
          // }, 100);
        }
      }
      // console.log("Validation errors:", errors); // Capture errors if form is invalid
    } else {
      // console.log("Form is valid, proceed to submit");
    }
  };


  return (
    <div>
      <Header />

      <div className="mx-auto w-[96%] xl:w-[97%] 2xl:w-[95%] mt-[90px] xl:mt-[120px]">
        <button className="flex bck">
          <Link to="/invoice_logs" className="flex">
            <span className="material-symbols-outlined pr-1 pt-0 xl:pt-[2px] text-[20px] xl:text-[24px]">keyboard_backspace</span>Back
          </Link>
        </button>
        <div className="grid grid-cols-12  gap-0 2xl:gap-4">
          <div className=" col-span-4 bg-gy1 p-4 cal-col1">
            {/* <h3>GWS Price Calculator</h3> */}
            <h4>Transaction Type:</h4>

            <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1 rtoLable">
              <div className="relative">
                <Radio.Group name="radiogroup" defaultValue={rtoValue}
                  value={rtoValue} onChange={rdoEvent}>
                  <Radio value={'GWS'}><span className="radioOptionLbl">GWS</span></Radio>
                  <Radio value={'Akku'}><span className="radioOptionLbl">Akku</span></Radio>
                </Radio.Group>
              </div>
            </div>
            {
              rtoValue == 'GWS' ? (
                <Formik initialValues={gwsPriceFormInitValues}
                  validationSchema={gwsPriceFormSchema}
                  validateOnMount={true}
                  // onSubmit={proposalSubmit}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      proposalSubmit(values);
                      setSubmitting(false);
                    } catch (error) {
                      console.error("Submission error", error);
                      setSubmitting(false);
                    }
                  }}
                  innerRef={productFormRef}>
                  {({ isSubmitting, setFieldValue, values, handleSubmit, handleChange, errors, resetForm, isValid }) => (
                    <>
                      {/* {handleError(isValid, errors)} */}
                      <Form onSubmit={handleSubmit} name="outerform" id="outerform">
                        {/* <h3>GWS Price Calculator</h3>
                        <h4>Transaction Details:</h4> */}
                        <h4>Transaction Details:</h4>
                        <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1">
                          <div className="relative">
                            <label className="block mb-2">Select Transaction Type</label>
                            <select
                              id="tran"
                              className="p-2.5 w-full  cursor-pointer"
                              name="transationType"
                              value={values.transationType}
                              onChange={(e) => handleTransationType(e.target.value, "transationType", setFieldValue, resetForm)}
                            >
                              <option value="new_business">New Business</option>
                              <option value="renewal">Renewal</option>
                              <option value="transfer_business">Transfer Business</option>
                            </select>
                            {/* <span className="material-symbols-outlined absolute right-3 top-4 cursor-pointer">keyboard_arrow_down</span> */}

                            <ErrorMessage name="transationType" component="div" className="error" />
                          </div>

                          <div className=" relative">
                            <label className="block mb-2">Contract Period</label>
                            <select
                              id="peri"
                              className="w-full p-2.5  cursor-pointer"
                              name="contractPeriod"
                              value={values.contractPeriod}
                              onChange={(e) => handleContractPeriodChange(e.target.value, setFieldValue, resetForm)}
                            >
                              <option className=" cursor-pointer" value="1">
                                One Year
                              </option>
                              <option value="3">Three Year</option>
                            </select>
                            {/* <span className="material-symbols-outlined absolute right-3 top-4 cursor-pointer">keyboard_arrow_down</span> */}

                            <ErrorMessage name="contractPeriod" component="div" className="error" />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <label className={`flex items-center cursor-pointer ${values.contractPeriod === "3" ? "not-allowed" : ""}`} style={{ position: "unset" }}>
                            <input
                              id="checked-checkbox"
                              type="checkbox"
                              className=" w-4 xl:w-5 h-4 xl:h-5 cursor-pointer"
                              name="priceGivenByGoogle"
                              checked={values.priceGivenByGoogle}
                              onChange={(e) => handlePriceGivenByGoogle("priceGivenByGoogle", e.target.checked, setFieldValue, resetForm)}
                              disabled={values.contractPeriod === "3"}
                            />
                            <p className="ms-2 text-[12px] xl:text-sm font-medium">Price Given By Google</p>
                          </label>
                        </div>
                        <ErrorMessage name="priceGivenByGoogle" component="div" className="error" />
                        <h4>Customer Details:</h4>
                        <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1">
                          <div className="">
                            <label className="block mb-2">Company Name</label>
                            <input
                              type="text"
                              id=""
                              className="block w-full p-2.5 bg-[#F7F7F7]"
                              placeholder=""
                              name="customerCompanyName"
                              value={values.customerCompanyName}
                              // onChange={(e) => setFieldValue("customerCompanyName", e.target.value)}
                              onChange={(e: any) => handleCustomerCompanyName(e.target.value, setFieldValue)}
                            />
                            <ErrorMessage name="customerCompanyName" component="div" className="error" />
                          </div>
                          <div className="">
                            <label className="block mb-2">Customer Name</label>
                            <input type="text" id="" className="block w-full p-2.5" name="customerName"
                              value={values.customerName}
                              // onChange={(e) => setFieldValue("customerName", e.target.value)}
                              onChange={(e: any) => handleCustomerName(e.target.value, setFieldValue)}
                            />
                            <ErrorMessage name="customerName" component="div" className="error" />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1">
                          <div className="">
                            <label className="block mb-2">Customer Email</label>
                            <input type="text" id="" className="block w-full p-2.5" placeholder=""
                              name="email" value={values.email}
                              // onChange={(e) => setFieldValue("email", e.target.value)}
                              onChange={(e: any) => handleEmail(e.target.value, setFieldValue)}
                            />
                            <ErrorMessage name="email" component="div" className="error" />
                          </div>
                          <div className="">
                            <label className="block mb-2">Customer Domain</label>
                            <input
                              type="text"
                              id=""
                              className="block w-full p-2.5"
                              placeholder=""
                              name="customerDomain"
                              value={values.customerDomain}
                              // onChange={(e) => setFieldValue("customerDomain", e.target.value)}
                              onChange={(e: any) => handleCustomerDomain(e.target.value, setFieldValue)}
                            />
                            <ErrorMessage name="customerDomain" component="div" className="error" />
                          </div>
                        </div>
                        <h4>Product Details:</h4>

                        <GWSProductItemForm
                          addProductItem={addProductItem}
                          googleGivenPriceShow={googleGivenPriceShow}
                          productPriceDetails1={productPriceDetails1}
                          productPriceDetails2={productPriceDetails2}
                          productPriceNotValid={productPriceNotValid}
                          priceVarifyPercentage={priceVarifyPercentage}
                          isSendQuote={isSendQuote}
                          approvalModalShowFromItemForm={approvalModalShowFromItemForm}
                          findFormFlag={findFormFlag}
                        />
                        {values.products.length > 0 ? (
                          <div className="seleted-product-list">
                            <FieldArray name="products">
                              {({ push, remove }) =>
                                values.products.map((product, index) => (
                                  <div className="list" key={index}>
                                    <div className="grid grid-cols-5 gap-3 align-center selected">
                                      <div className="col-span-2  relative">
                                        <label className="block mb-2">Plan</label>
                                        <Field
                                          as="select"
                                          id={`products[${index}].productType`}
                                          name={`products[${index}].productType`}
                                          className="p-2.5 w-full peri-selected"
                                          onChange={(e: any) => handleProductTypeChange(index, e.target.value, setFieldValue)}
                                        >
                                          <option value="business">Business</option>
                                          <option value="enterprise">Enterprise</option>
                                          <option value="frontline">Frontline</option>
                                        </Field>
                                        {/* <span className="material-symbols-outlined absolute right-3 top-4 cursor-pointer">keyboard_arrow_down</span> */}

                                        <ErrorMessage name={`products[${index}].productType`} component="div" className="error" />
                                      </div>

                                      <div className="col-span-2 relative">
                                        <label className="block mb-2">SKU</label>

                                        {product.productType && (
                                          <>
                                            <Field
                                              as="select"
                                              id={`products[${index}].subPlan`}
                                              name={`products[${index}].subPlan`}
                                              className="p-2.5 w-full peri-selected "
                                              // className="p-2.5 w-full peri-selected dropdown-proposal"
                                              value={values.products[index].subPlan || ""}
                                              onChange={(e: any) => handleSubPlanChange(index, e.target.value, setFieldValue)}
                                            >
                                              {product.productType === "business" && (
                                                <>
                                                  <option value="starter">Starter</option>
                                                  <option value="standard">Standard</option>
                                                  <option value="plus">Plus</option>
                                                </>
                                              )}
                                              {product.productType === "enterprise" && (
                                                <>
                                                  <option value="starter">Starter</option>
                                                  <option value="standard">Standard</option>
                                                  <option value="plus">Plus</option>
                                                </>
                                              )}
                                              {product.productType === "frontline" && (
                                                <>
                                                  <option value="starter">Basic</option>
                                                  <option value="standard">Plus</option>
                                                </>
                                              )}
                                            </Field>
                                            {/* <span className="material-symbols-outlined absolute right-3 top-4 cursor-pointer">keyboard_arrow_down</span> */}
                                            {/* <span className="material-symbols-outlined absolute right-3 top-4 cursor-pointer">keyboard_arrow_down</span> */}

                                            <ErrorMessage name={`products[${index}].subPlan`} component="div" className="error" />
                                          </>
                                        )}
                                      </div>
                                      <div className="col-span-1">
                                        <label className="block mb-2">Licenses</label>
                                        <Field
                                          type="number"
                                          value={values.products[index].licenses || ""}
                                          id={`products[${index}].licenses`}
                                          name={`products[${index}].licenses`}
                                          className="block w-full p-2.5"
                                          onChange={(e: any) => handleLicensesChange(index, e.target.value, setFieldValue)}
                                        />
                                        <ErrorMessage name={`products[${index}].licenses`} component="div" className="error" />
                                        {(product.licenses == "" || product.licenses === 0) && <div className="error">Licenses are required</div>}
                                      </div>
                                    </div>
                                    <div className="grid grid-cols-5 gap-2 align-center mb-5 selected2">
                                      {values.priceGivenByGoogle ? (
                                        <div className="col-span-2">
                                          <label className="block mb-2">Google price</label>
                                          <Field
                                            type="number"
                                            value={values.products[index].googlePrice || ""}
                                            id={`products[${index}].googlePrice`}
                                            name={`products[${index}].googlePrice`}
                                            className="block w-full p-2.5"
                                            onChange={(e: any) => handleGooglePriceChange(index, e.target.value, setFieldValue)}
                                          />
                                          <ErrorMessage name={`products[${index}].googlePrice`} component="div" className="error" />
                                          {(product.googlePrice == "" || product.googlePrice === 0) && <div className="error">Google price is required</div>}
                                        </div>
                                      ) : null}

                                      <div className="col-span-2">
                                        <label className="block mb-2">Customer Price</label>
                                        <Field
                                          type="number"
                                          value={values.products[index].CustomerGivenPrice || ""}
                                          id={`product[${index}].CustomerGivenPrice`}
                                          name={`product[${index}].CustomerGivenPrice`}
                                          className="block w-full p-2.5"
                                          onChange={(e: any) => handleCustomerGivenPriceChange(index, e.target.value, setFieldValue)}
                                        />
                                        <ErrorMessage name={`products[${index}].CustomerGivenPrice`} component="div" className="error" />
                                        {(product.CustomerGivenPrice == "" || product.CustomerGivenPrice === 0) && <div className="error">Customer price is required</div>}
                                      </div>
                                      {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
                                      {/* <pre>{JSON.stringify(product, undefined, 2)}</pre> */}
                                      {googleGivenPriceShow ? (
                                        <div className="col-span-1">
                                          <div className="flex ">
                                            <button className="n-user2 " type="button"
                                              onClick={() => {
                                                remove(index);
                                                removeItemTrigger();
                                              }}>
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="col-span-3 flex justify-end">
                                          <div className="flex">
                                            <button className="n-user2  " type="button"
                                              onClick={() => {
                                                remove(index);
                                                removeItemTrigger();
                                              }}>
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))
                              }
                            </FieldArray>
                          </div>
                        ) : (
                          <></>
                        )}

                        <ErrorMessage name="products" component="div" className="error" />
                        <div className="flex justify-center mt-7">
                          {/* <button type="submit" className="n-user flex align-center calculateBtn"
                            id="calculate" name="calculate"
                          // disabled={!isValid || isSubmitting || values.products.length === 0}

                          >
                            Calculate Price
                          </button> */}

                          {/* <button
                            type="submit"
                            className="n-user flex align-center calculateBtn"
                            id="calculate"
                            name="calculate"
                            onClick={() => handleFormSubmit(setFieldValue, errors)} // Call custom submit handler
                          >
                            Calculate Price
                          </button> */}

                          <button
                            type="submit"
                            className="n-user flex align-center calculateBtn"
                            id="calculate"
                            name="calculate"
                            onClick={() => {
                              if (!isSubmitting) handleFormSubmit(setFieldValue, errors);
                            }}
                          >
                            Calculate Price
                          </button>

                          <button type="button"
                            style={{ display: 'none' }}
                            id="handleResetBtn" name="handleResetBtn"
                            onClick={() => handleReset(resetForm)}>
                          </button>
                        </div>

                        {/* <br></br>
                        <pre>{JSON.stringify(values, undefined, 2)}</pre>
                        <pre>{JSON.stringify(values.products.length, undefined, 2)}</pre>
                        <pre>{JSON.stringify(isValid)}</pre>
                        <pre>{JSON.stringify(isSubmitting)}</pre> */}

                      </Form>
                    </>
                  )}
                </Formik>
              ) : (
                <AkkuProductForm
                  akkuProductFormData={akkuProductFormData}
                  akkuFormUpdatedData={akkuFormUpdatedData}
                  hideAccordion={hideAccordion}>
                </AkkuProductForm>
              )
            }
          </div>

          <Modal title="" open={approvalModalOpen} onCancel={approvalModalCancel} footer={false}>
            <div className="grid grid-cols-1 gap-6 align-center mb-0 xl:mb-5 frm1">
              <div className="text-center">
                {/* <img className="m-auto w-auto" src={img1} alt="Your Company" /> */}

                <h2 className="pt-2 text-[22px] xl:text-[28px]">Request send quote</h2>
                <p className="pt-4 text-[15px] xl:text-[18px]">Contact Dinesh to get approvals for this price?</p>
              </div>
              <div className=""></div>
            </div>
            <div className="flx">
              <button type="button" onClick={sendRequestQuote} className="dow1">
                Send Request
              </button>
              <button type="button" onClick={cancelRequestQuote} className="dow1">
                Cancel Request
              </button>
            </div>
          </Modal>
          {/* <div>Test: {JSON.stringify(invoiceApproveStatus)}</div> */}

          <div className=" col-span-8 px-4 cal-col2">
            <div className="accordian">
              <Accordian
                allData={allData}
                isAccordionOpen={isAccordionOpen}
                activeKeys={activeKeys}
                invoiceData={invoiceData}
                checkAllFormVarify={checkAllFormVarify}
                rtoValue={rtoValue}
                isSendQuote={isSendQuote}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newproposal;
