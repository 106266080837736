import { createSlice } from "@reduxjs/toolkit";
interface invoiceState {
    approve: any;
}


const initialState: invoiceState = {
    approve: [],
};


const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setQuoteApprovals(state, action) {
            state.approve = action.payload;
        },
        getQuoteApprovals(state, action) {
            return state.approve;
        },
    },
});

export const { setQuoteApprovals, getQuoteApprovals } = invoiceSlice.actions;
export default invoiceSlice.reducer;