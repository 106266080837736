import React, { useEffect, useState } from "react";
import { Table, TableProps } from "antd";
import { Button, Modal } from "antd";
import "../../src/index.css";
import "../../src/App.scss";
import { triggerNotification } from "../component/Toster";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import pluspng from "../assets/plus.png";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import axios from "axios";
import history from "../assets/history.png";
import { apiUrl } from '../services/baseUrl';

const InvoiceHistorySidebar = (props: any) => {
  const navigate = useNavigate();
  const [invoiceHistoryList, setInvoiceHistoryList] = useState<any>(null);

  useEffect(() => {
    fetchData(props);
  }, [props]);

  // Get
  const fetchData = async (props: any) => {
    try {
      // props.selectedInvoiceRow.invoice_id
      const formdata = new FormData();
      formdata.append("invoice_id", props.selectedInvoiceRow.invoice_id);

      const response = await fetch(apiUrl + "fetchInvoiceHistory.php", {
        method: "POST",
        body: formdata,
      });
      const result = await response.json();

      if (result.status == "success") {
        if (result.message == "No invoice history found for the given invoice ID.") {
          triggerNotification("error", "Error", "No invoice history", "topRight", "pop-color");
          setInvoiceHistoryList([]);
        } else {
          if (result.data && result.data) {
            setInvoiceHistoryList(result.data);
          }
        }
      } else {
        setInvoiceHistoryList([]);
        triggerNotification("error", "Error", "No invoice history", "topRight", "pop-color");
      }
    } catch (error) {
      console.error(error);
      setInvoiceHistoryList([]);
      triggerNotification("error", "Error", "No invoice history", "topRight", "pop-color");
    } finally {
    }
  };
  const formatIndianCurrency = (amount: any) => {
    // Ensure the amount is a number and remove any existing commas
    const parsedAmount = parseFloat(amount.toString().replace(/,/g, "")).toFixed(2);

    // Split into integer and decimal parts
    const parts = parsedAmount.split(".");

    // Format the integer part with commas for Indian numbering system
    const integerPart = parts[0];
    let lastThreeDigits = integerPart.substring(integerPart.length - 3);
    let otherDigits = integerPart.substring(0, integerPart.length - 3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }
    const formattedRupees = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    // Combine and return the formatted amount
    return "₹" + formattedRupees + "." + parts[1];
  };
  const separateEmail = (email: any) => {
    const [username, domain] = email.split("@");
    return username;
  };

  const generateName = (baseName: any) => {
    const nameParts = baseName?.split(".");
    const firstName = nameParts[0];
    return firstName;
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const gotoQuotes = (data: any) => {
    console.log(data);

    sessionStorage.selectedQuote = JSON.stringify(data);
    navigate("/Quotes");
  };
  console.log("locall:", invoiceHistoryList);

  return (
    <div className=" bg-gy2  ">
      <div className=" flex justify-between items-center  pl-3 pr-2 pt-1 xl:pt-4 text-center">
        <div className="">
          <h4 className="version-history-title">Version History</h4>
          {/* <pre>{JSON.stringify(invoiceHistoryList[0].company_name)}</pre> */}
        </div>
        <div className=" pt-1">
          <img
            src={pluspng}
            className=" cursor-pointer w-[25px] xl:w-[36px] h-auto"
            onClick={() => {
              props.handleHideView();
              props.setCheckIndex();
            }}
          />
        </div>
      </div>
      <div>
        {" "}
        <p className=" text-[#6A6A65] pl-3 pb-3 text-[11px] xl:text-[16px]">{invoiceHistoryList && invoiceHistoryList[0]?.company_name ? invoiceHistoryList[0]?.company_name : "-"}</p>
      </div>
      {invoiceHistoryList && invoiceHistoryList.length > 0 ? (
        invoiceHistoryList.map((history: any, index: any) => (
          // to = "/Quotes"
          <div key={index} onClick={() => gotoQuotes(history)} className={` ${index == "0" ? "bg-[#EAEAEA]" : ""}`}>
            <div className="grid grid-cols-12 pt-4 amf ">
              <div className="col-span-7 cursor-pointer">
                <p className="date">{formatDate(history.create_on)}</p>
              </div>
              <div className="col-span-5 cursor-pointer">
                <p className="tot">{formatIndianCurrency(JSON.parse(history.invoice_data).totals.Total)}</p>
              </div>
            </div>
            <div className=" px-[10px] cursor-pointer ">
              {index == "0" && <p className="cun">Current version</p>}
              <p className="edi">Edited by {history.user_name ? generateName(history.user_name) : "--"}</p>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};
export default InvoiceHistorySidebar;
